import * as React from "react"
import tw from "twin.macro"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContentContainer from "../components/ContentContainer"
import ContentParagraph from "../components/ContentParagraph"
import Content from "../components/Content"
import { graphql, useStaticQuery } from "gatsby"
import ContentHeroTitle from "../components/ContentHeroTitle"

const MultichainPage = () => {
  const { heroBgImage } = useStaticQuery(
    graphql`
      query {
        heroBgImage: file(relativePath: { eq: "hero-bg-3.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

  return (
    <Layout noPadding={true} headerFixed={false}>
      <React.Fragment>
        <SEO title="Multichain protocols | Long Rock Capital"/>
        <ContentHeroTitle headerImage={heroBgImage.childImageSharp.fluid} title={"Multichain protocols"}/>
        <ContentContainer>
          <Content>
            <ContentParagraph>
              We have a deep understanding of the blockchain landscape and we undertake original research to find new trends in the space.
            </ContentParagraph>
            <ContentParagraph>
              We help companies leverage DLTs to optimize their business processes.
            </ContentParagraph>
            <ContentParagraph>
              Integrate with the best networks by seamlessly sharing data and transactions across networks.
            </ContentParagraph>
          </Content>
        </ContentContainer>
      </React.Fragment>
    </Layout>
  )
}

export default MultichainPage
import * as React from "react"
import tw from "twin.macro"

import { FluidObject } from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const Container = tw.div`pt-48 pb-32 mb-32`

const Title = tw.h1`text-5xl font-semibold text-white text-center`

export interface ContentHeroTitleProps {
  headerImage: FluidObject
  title: string
}

const ContentHeroTitle = (props: ContentHeroTitleProps) => {
  return (
      <BackgroundImage fluid={props.headerImage}>
        <Container>
          <Title>{props.title}</Title>
        </Container>
      </BackgroundImage>
  )
}

export default ContentHeroTitle